import IMAGES from 'assets/images';
import { useToast } from 'core/toast';
import { ColourTheme, SyncNamespace } from 'core/utilities';
import { MediaQuery } from 'core/config';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSynchronizedState } from '../hooks';
import { ColourPicker, COLOUR_MAP } from './ColourPicker';

// @ts-ignore
import { LiquidDistortionText, SplitColorChannelText } from 'react-text-fun';

export const About : React.FunctionComponent = () =>
{
    const { setToastMessage } = useToast();
    const [ loading, setLoading ] = useSynchronizedState<boolean>(SyncNamespace.INITIAL_LOAD, true);
    const [ colour ] = useSynchronizedState<ColourTheme>(SyncNamespace.THEME_COLOUR, ColourTheme.DARK);
    const isDarkTheme = colour === ColourTheme.DARK;

    const [ _, setTransition ] = useSynchronizedState<boolean>(SyncNamespace.TOGGLE_FRAG, false);

    return (
        <>
            {/* <MobileOverlay /> */}
            <Container>
                <Title>
                    <LiquidDistortionText
                        text={'Jackie Jabson'}
                        fontFamily={'custom'}
                        fontSize={55}
                        speed={0.3}
                        volatility={0.02}
                    />
                </Title>
                {/* <Title isLoading={loading}>
                    <ColourPicker />
                    Rhys Balevicius
                </Title>
                <WalletAddress 
                    isLoading={loading} 
                    onClick={onAddressClick}
                    onMouseOver={onMouseOver}
                    onMouseOut={onMouseOut}
                >
                    {WALLET_ADDRESS}
                </WalletAddress> */}
                {/* <Blurb isLoading={loading}>
                    I'm a polyglot software engineer and 
                    <Link 
                        onClick={onLinkClick('https://patents.google.com/?inventor=rhys+balevicius&oq=inventor:(rhys+balevicius)')}
                        onMouseOver={onMouseOver}
                        onMouseOut={onMouseOut}
                        marginRight={true}
                    >
                        inventor
                    </Link>
                    based 
                    in Toronto, Canada.
                    {'\n\n'}
                    Currently innovating in the areas of blockchain technology, infrastructure, and DevOps over at
                    <Link 
                        onClick={onLinkClick('https://www.dropverse.com')}
                        onMouseOver={onMouseOver}
                        onMouseOut={onMouseOut}
                    >
                        Dropverse
                    </Link>.
                </Blurb> */}
                {/* <Socials isLoading={loading}>
                    <SocialLink 
                        src={IMAGES.linkedin}
                        isDarkTheme={isDarkTheme}
                        onClick={onLinkClick('https://www.linkedin.com/in/rhys-balevicius/')}
                        onMouseOver={onMouseOver}
                        onMouseOut={onMouseOut}
                    />
                    <SocialLink 
                        src={IMAGES.github}
                        isDarkTheme={isDarkTheme}
                        onClick={onLinkClick('https://www.github.com/rhysbalevicius')}
                        onMouseOver={onMouseOver}
                        onMouseOut={onMouseOut}
                    />
                    <Email 
                        onClick={onLinkClick('mailto:Rhys Balevicius<development@rhys.tech>')}
                        onMouseOver={onMouseOver}
                        onMouseOut={onMouseOut}
                    >development@rhys.tech</Email>
                </Socials> */}
            </Container>
        </>
    );
};

type AboutProps = { 
    isLoading?   : boolean; 
    isDarkTheme? : boolean;
    colour?      : string;
    marginRight? : boolean;
};

 
const Container = styled.div<AboutProps>`
    display: flex; 
    flex-direction: column;
    position: absolute;
    top: 0;
    user-select: none;
    line-height: 30px;
    letter-spacing: 1px;
    font-size: 16px;
    width: 100%;
    height: 100vh;

    @media ${MediaQuery.tablet} {
        font-size: 20px;
    }

    z-index: 1;
`;

const Title = styled.div<AboutProps>`
    display:flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    filter: blur(2px);
`;

const WalletAddress = styled.div<AboutProps>`
    font-size: 10px;
    font-style: italic;
    font-weight: 100;
    opacity: 0.6;
    user-select: text;
    transition: opacity 1.5s linear, margin-top 1s ease-out, margin-bottom 0.5s linear;
    transition-delay: 0.2s;

    margin-top: -4px;
    margin-bottom: 25px;


    @media ${MediaQuery.tablet} {
        margin-bottom: 35px;
    }
`;

const Blurb = styled.div<AboutProps>`
    white-space: pre-line;
    max-width: 535px;
    transition: opacity 1.5s linear, margin-top 1s ease-out;
    transition-delay: 0.4s;
`;

const Link = styled.span<AboutProps>`
    margin-left: 8px;
    // margin-right: 8px;
    margin-right: ${({ // @ts-ignore
        marginRight 
    }) => marginRight ? '8px' : '0'};
    cursor: pointer;
    border-bottom: solid #5D737E 2px;
    transition: box-shadow .3s;
    :hover {
        box-shadow: inset 0 -1.5em 0 0 #5D737E;
    }
`;

const Email = styled(Link)`
    font-size: 12px;
    position: absolute;
    right: 0;
    top: -5px;
    bottom: -3px;
    border-bottom: solid #723D46 2px;

    :hover {
        box-shadow: inset 0 -1.5em 0 0 #723D46;
    }
`;

const Socials = styled.div<AboutProps>`
    width: 100%;
    height: 18px;
    bottom: -32px;
    position: absolute;
    transition: opacity 1.5s linear, margin-top 1s ease-out;
    transition-delay: 0.5s;
`;

const SocialLink = styled.div<{ isDarkTheme: boolean; src: string; }>`
    margin-right: 17px;
    width: 17px;
    height: 17px;
    display: inline-block;
    background: url(${({ src }) => src});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    cursor: pointer;

    filter: ${({ isDarkTheme }) => (
        isDarkTheme 
            ? 'invert(99%) sepia(1%) saturate(7496%) hue-rotate(206deg) brightness(111%) contrast(98%)'
            : 'invert(14%) sepia(1%) saturate(4017%) hue-rotate(329deg) brightness(99%) contrast(84%)'
    )};
    opacity: 0.2;
    :hover { opacity: 0.75; }
    transition: opacity 0.2s ease-in, filter 0.2s ease-in;
`;

const MobileOverlay = styled.div`
    width : 100%;
    height : 100vh;
    background-color: rgba(20,20,20,0.75);
    top: 0;
    left: 0;
    position: absolute;
    z-index: 0;

    // @media ${MediaQuery.tablet} {
    //     display: none;
    // }
`;