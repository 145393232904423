import {  BrowserRouter, Switch } from 'react-router-dom';
import Route, { RouteProps, RouteAccess } from './Route';
import { useAuthToken } from 'view/hooks/useAuthToken';
import { MainLayout, Spinner } from 'view/components/layout';
import {
    RootPage
} from 'view/pages';
import { Section } from 'view/components/common';

export enum RoutePath
{
    root        = '/'
}

const routes : Array<RouteProps> = [
    {
        path      : RoutePath.root,
        access    : RouteAccess.public,
        layout    : MainLayout,
        component : RootPage
    }
];

const AppRouter : React.FunctionComponent = () => 
{
    return (
        <BrowserRouter>
            <Switch>
                {
                    routes.map(({ path, access, component, ...props }: RouteProps) => 
                    {
                        return (
                            <Route 
                                key={path}
                                path={path}
                                access={access}
                                component={component}
                                {...props}
                            />
                        );
                    })
                }
            </Switch>
        </BrowserRouter>
    );
};

export default AppRouter;