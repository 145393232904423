import React from 'react';
import { AuthProvider } from 'core/auth';
import { ToastProvider } from 'core/toast';
import { AppRouter } from 'core/router';
import './index.css';

const App : React.FunctionComponent = () => 
{
    return (
        <AppRouter />
    );
};

export default App;